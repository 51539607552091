import React from "react";
import {Link} from "gatsby";

export const IndexOverview = ( { json } ) => {
    const { nodes } = json.allStrapiCategories;

    return <div className="container tw-mx-auto tw-py-6">
        {nodes.map(n => {
                const { subCategories, title } = n;

                return <div>
                    <h2 className="text-2xl font-bold mb-6">{subCategories.title}</h2>
                    <div className="tw-grid lg:tw-grid-cols-3 tw-gap-5 tw-mb-16">
                        {subCategories.map((category) => (
                            <Link to={"/kategorie/" + category.shopifyCollection.handle}>
                                <div key={category.id} className="hover:tw-shadow-xl hover:tw-cursor-pointer tw-rounded-lg overflow-hidden tw-shadow-md tw-bg-white">
                                    <img
                                        src={category.shopifyCollection.imageUrl}
                                        alt={category.shopifyCollection.title}
                                        className={"tw-w-full tw-object-contain tw-bg-white"}
                                        height={300}
                                    />
                                    <div className="tw-p-4 tw-text-center">
                                        <h3 className="tw-text-xl tw-font-bold tw-mb-2">{category.shopifyCollection.title}</h3>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
            </div>
            })
        }
    </div>


}
