export const getStrapiUrl = () => {

    return "https://strapi.golfsport.at";

    if (process.env.NODE_ENV === 'development') {
      //  return "http://localhost:1337";
    } else {
        return "https://strapi.golfsport.at";
    }
}
